import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Locales
import en from '~/assets/locales/en.json';
import es from '~/assets/locales/es.json';

export enum Language {
  English = 'en-US',
  Spanish = 'es-ES'
}

const resources = {
  [Language.English]: {
    translation: en
  },
  [Language.Spanish]: {
    translation: es
  }
};

export default i18n;

export function getCurrentLanguage(): string {
  return i18n.language;
}

export type LanguageReturnType = [string, string];

export function getLanguagesList(): LanguageReturnType[] {
  return Object.entries(Language).map((value) => {
    return value;
  });
}

export function changeCurrentLanguage(language: Language): void {
  i18n.changeLanguage(language);
}

export function existsLanguageByCode(code: string): string[] {
  return Object.entries(Language).find(([, value]) => {
    const lowCode = code.toLowerCase();
    return value.toLowerCase() === lowCode || value.split('-')[0].toLowerCase() === lowCode;
  });
}

const i18nSetUp = (internationalization: typeof i18n) => {
  const defaultLanguage = Language.English;

  const languageFromBrowserSettings =
    (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language;

  const matchUrl = document.location.href.match(/\/([\w]{2})-([\w]{2})\/?/g);
  const languageFromBrowserUrl = matchUrl ? matchUrl[0].replace(/\//g, '') : null;

  const initialLanguage = languageFromBrowserUrl || languageFromBrowserSettings;

  const languageCodeChecked = existsLanguageByCode(initialLanguage);
  const languageName = languageCodeChecked ? languageCodeChecked[1] : defaultLanguage;

  internationalization
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      nsSeparator: false,
      resources,
      lng: languageName, // Change current language on the end of this file
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
};

i18nSetUp(i18n);
