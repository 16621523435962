import Cookies from 'universal-cookie';
const universalCookies = new Cookies();
const secondsInOneDay = 86400;

const get = (cookieName: string) => {
    return universalCookies.get(cookieName);
}

const set = (cookieName: string, value: string, maxAgeInDays = 30) => {
    universalCookies.set(cookieName, value, { path: '/', maxAge: maxAgeInDays * secondsInOneDay });
}

export const cookies = {
    get,
    set
};
