import React, { useCallback, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import { Img, SourceLinkType, Video, VideoPlayer } from '~/app/shared';
import { ReactComponent as ArrowLeft } from '~/assets/icons/Arrow-left.svg';
import { ReactComponent as ArrowRight } from '~/assets/icons/Arrow-right.svg';
import { SourceLink } from '../sourceLink';
import './slider.module.scss';

const PREV_SLIDE = 37;
const NEXT_SLIDE = 39;

type SliderProps = {
  assets: (Img & Video)[];
  source?: SourceLinkType;
};

export function Slider({ assets, source }: SliderProps) {
  const slideCarouselRef = useRef<any>(null);
  const [position, setPosition] = useState<number>(0);
  const [t] = useTranslation();

  const handlePosition = useCallback((selectedIndex: number) => {
    setPosition(selectedIndex);
  }, []);

  function next() {
    slideCarouselRef.current.next();
    slideCarouselRef.current.element.focus();
  }

  function prev() {
    slideCarouselRef.current.prev();
    slideCarouselRef.current.element.focus();
  }

  function onKeyDownPress(key: number, expectedKey: number) {
    if (key === expectedKey && key === PREV_SLIDE) {
      prev();
    }
    if (key === expectedKey && key === NEXT_SLIDE) {
      next();
    }
  }

  return (
    <>
      <Carousel
        tabIndex={0}
        activeIndex={position}
        onSelect={handlePosition}
        ref={slideCarouselRef}
        interval={null}
        controls={false}
        styleName="slider"
        indicators={false}
      >
        {assets &&
          assets.map((asset, i) => {
            return (
              <Carousel.Item key={`asset-${i}`}>
                {asset.sources ? (
                  <div className="mt-3" styleName="slider-video_wrapper">
                    <VideoPlayer video={asset} />
                  </div>
                ) : (
                  <div className="mb-2 mt-3" styleName="slider-img_wrapper">
                    <img styleName="slider-img" src={asset.src} alt={asset.alt} />
                    <span styleName="slider-captions">{asset.alt}</span>
                  </div>
                )}
              </Carousel.Item>
            );
          })}
      </Carousel>

      {assets.length === 1 && source && <SourceLink source={source}></SourceLink>}

      {assets.length > 1 && (
        <div styleName="slider-controls" className="d-flex">
          {source ? <SourceLink source={source}></SourceLink> : ''}
          <div className="d-flex">
            <button
              aria-label={t('global.prev-slide')}
              className="btn no-shadow mr-1"
              disabled={position === 0}
              styleName={`${position === 0 ? 'is-disabled' : ''}  slider-button`}
              onClick={prev}
              onKeyDown={(e: any) => onKeyDownPress(e.keyCode, PREV_SLIDE)}
            >
              <ArrowLeft />
            </button>
            <button
              aria-label={t('global.next-slide')}
              className="btn no-shadow"
              disabled={position === assets.length - 1}
              styleName={`${position === assets.length - 1 ? 'is-disabled' : ''}  slider-button`}
              onClick={next}
              onKeyDown={(e: any) => onKeyDownPress(e.keyCode, NEXT_SLIDE)}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
