import React, { useCallback, useRef, useState } from 'react';
import { Video } from '~/app/shared';
import './videoPlayer.module.scss';
import homeIMG from '../../../assets/images/home-video.jpg';
import { useTranslation } from 'react-i18next';

type Props = {
  video: Video;
  autoHeight?: boolean;
  staticWidth?: boolean;
  thumbnail?: boolean;
};

export const VideoPlayer = ({ video, autoHeight, staticWidth, thumbnail }: Props) => {
  const videoRef = useRef<any>();
  const [showOverlay, setShowOverlay] = useState<boolean>(true);
  const [t] = useTranslation();

  const onPlayButtonClick = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    setShowOverlay(false);
    videoRef.current.play();
  }, []);

  return (
    <div styleName={`video_player ${staticWidth ? ' video_player-static_width' : ''}`}>
      <video
        controls
        ref={videoRef}
        preload="metadata"
        styleName={`video_player-video${autoHeight ? ' video_player-auto_height' : ''} ${
          staticWidth ? ' video_player-static_width' : ''
        }`}
        crossOrigin="anonymous"
      >
        {video.sources.map((source, index) => {
          return <source key={`${source.url}-${index}`} src={source.url} type={source.type} />;
        })}
        {video.tracks &&
          video.tracks.map((track, index) => {
            return (
              <track
                key={`${track.url}-${index}`}
                kind="captions"
                srcLang={track.srcLang}
                src={track.url}
                default={track.default}
              />
            );
          })}
      </video>
      {thumbnail && (
        <div styleName={`video_player-overlay ${!showOverlay ? ' hidden' : ''}`}>
          <div styleName="video_player-overlay--content">
            <button
              aria-label={t('global.play')}
              tabIndex={0}
              styleName="video_player-play_button"
              onClick={onPlayButtonClick}
            >
              <svg styleName="video_player-player" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M505.618 408.764L293.606 57.81c-17.166-28.611-58.552-28.495-75.634-.027L6.377 408.776c-.005.009-.011.018-.016.027-17.646 29.409 3.506 66.806 37.825 66.806h423.631c34.267-.001 55.506-37.335 37.801-66.845zm-37.802 36.849H44.186c-10.966 0-17.742-11.938-12.12-21.35l211.611-351.02c5.464-9.108 18.695-9.184 24.229.039l212.011 350.954c5.647 9.408-1.115 21.377-12.101 21.377z" />
              </svg>
            </button>
          </div>
          <img src={homeIMG} alt="deepfake video" />
        </div>
      )}
    </div>
  );
};
