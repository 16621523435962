export function stopAllVideos() {
    const videos = document.querySelectorAll<HTMLVideoElement>(`video`);
  
    for (let i = 0; i < videos.length; i++) {
        const video = videos[i];

        if(!video.paused) {
            video.pause();
        }
    }
  }
  