export enum QuestionType {
  default = 'default',
  zoom = 'zoom',
  slide = 'slide'
}

export enum AnswerType {
  default = 'default',
  any = 'any',
  all = 'all'
}

type Link = {
  value: string;
  label: string;
}

export type SourceLinkType = {
  text: string;
  link: string;
}

export interface IQuestion {
  id: number;
  order: number;
  type: QuestionType;
  answerType: AnswerType;
  title: string;
  description: string;
  hint: Hint;
  answers: Answer[];
  correct: AnswerResult;
  wrong: AnswerResult;
  link?: Link;
  outro: Outro;
  source?: SourceLinkType;
}

export type QuestionZoom<T> = IQuestion & { assets: T[] };
export type QuestionSlide<T> = IQuestion & { assets: T[] };

export type Hint = {
  title: string;
  description: string;
};

export type Answer = {
  id: string;
  description: string;
  isCorrect: boolean;
};

export type Outro = {
  title: string;
  description?: string;
  link?: string;
  linkValue?: string;
};

export type AnswerResult = {
  title: string;
  description: string;
};

export type Img = {
  answerId?: string;
  src: string;
  alt: string;
};

export type Source = {
  type: string;
  url: string;
};

export type Track = {
  default?: boolean;
  srcLang: string;
  url: string;
}

export type Video = {
  answerId?: string;
  sources: Source[];
  tracks: Track[];
};
