import { getCurrentLanguage } from '~/app/app.i18n';
import { IQuestion } from '.';

type LangCode = 'en' | 'es';

function getLngCode(): LangCode {
  const lng = getCurrentLanguage();
  return lng.substring(0, lng.indexOf('-')) as LangCode;
}

let questionsData: IQuestion[] = [];

export async function questions() {
  const code = getLngCode();

  if (questionsData.length === 0) {
    const resourse = await import(`~/assets/questions/${code}.json`);
    questionsData = resourse.default as IQuestion[];
  }

  return {
    get() {
      return questionsData;
    },

    getById(id: number) {
      return questionsData.find((question) => question.id === id);
    },

    delete() {
      questionsData = [];
    }
  };
}
