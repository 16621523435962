import React from 'react';
import './footer.module.scss';

type FooterLinkProps = {
  url: string;
  imgAlt: string;
  imgSrc: string;
  isSmall?: boolean;
};

export function FooterLink({ url, imgSrc, imgAlt, isSmall }: FooterLinkProps) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img styleName={`footer-logo ${isSmall ? 'footer-logo--small' : ''}`} src={imgSrc} alt={imgAlt} />
    </a>
  );
}
