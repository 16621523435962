import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import App from './app/App';
import { getSettings } from './settings';

(async () => {
  await getSettings();

  ReactDOM.render(<App />, document.getElementById('root'));
})();
