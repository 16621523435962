// From: https://stackoverflow.com/questions/45787459/convert-number-to-alphabet-string-javascript
export function indexToAlphabet(num: number): string {
  let s = '',
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }

  return s || undefined;
}
