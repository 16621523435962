import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTopHelper } from './shared/helper/scrollToTopHelper';

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTopHelper();
  }, [pathname]);

  return <></>;
}
