import React from 'react';
import { useTranslation } from 'react-i18next';

import { SourceLinkType } from '~/app/shared';
import './sourceLink.module.scss';

type Props = {
    source?: SourceLinkType;
}

export const SourceLink = ({ source }: Props) => {
    const [t] = useTranslation();

    return <a styleName="source-link" href={source.link} target="_blank" rel="noopener noreferrer" >{t(source.text)}</a >
};
