import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import './skip.module.scss';

type Props = {
    to: string;
};

export function Skip({ to }: Props) {
    const [t] = useTranslation();

    const onClick = () => {
        (document.activeElement as HTMLElement).blur();

        const element = document.querySelector<HTMLElement>(`#${to || 'main'}`);

        if (element) {
            element.focus();
        }
    };

    return (
        <Link offset={56} styleName="skip" smooth={true} to="" href={`#${to || 'main'}`} onClick={onClick}>
            {t('skip-to-main')}
        </Link>
    )
}
