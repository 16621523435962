import { useEffect, useState } from 'react';
import { IQuestion } from './question.model';
import { questions } from './questions.service';

function useGet() {
  const [questionsData, setQuestionsData] = useState<IQuestion[]>(null);
  useEffect(() => {
    async function getQuestions() {
      try {
        const questionService = await questions();
        const questionsData = questionService.get();
        setQuestionsData(questionsData);
      } catch (error) {
        console.error(error);
      }
    }

    getQuestions();
  }, []);

  return [questionsData, setQuestionsData] as const;
}

function useGetById(id: number) {
  const [questionData, setQuestionData] = useState<IQuestion>(null);
  useEffect(() => {
    async function getQuestion() {
      try {
        const questionService = await questions();
        const questionData = questionService.getById(id);
        setQuestionData(questionData);
      } catch (error) {
        console.error(error);
      }
    }

    getQuestion();
  }, [id]);

  return [questionData, setQuestionData] as const;
}

export const useQuestions = {
  get: useGet,
  getById: useGetById
};
