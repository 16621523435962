import { useTranslation } from 'react-i18next';
import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { ReactComponent as FacebookSVG } from '~/assets/icons/facebook.svg';
import { ReactComponent as LinkedinSVG } from '~/assets/icons/linkedin.svg';
import { ReactComponent as TwitterSVG } from '~/assets/icons/twitter.svg';
import './share.module.scss';
const url = window.location.href;

type ShareProps = {
  externalText?: string;
};

export function Share({ externalText }: ShareProps) {
  const [t] = useTranslation();
  const text = externalText ? externalText : t('share.social');

  return (
    <div styleName="share">
      <span>{t('share.text')}</span>
      <TwitterShareButton title={text} url={url} className="d-flex ml-3">
        <TwitterSVG title="Twitter" styleName="share-icon share-icon--twitter" />
      </TwitterShareButton>
      <FacebookShareButton quote={text} url={url} className="d-flex ml-3">
        <FacebookSVG title="Facebook" styleName="share-icon share-icon--facebook" />
      </FacebookShareButton>
      <LinkedinShareButton summary={text} url={url} className="d-flex ml-3">
        <LinkedinSVG title="Linkedin" styleName="share-icon share-icon--linkedin" />
      </LinkedinShareButton>
    </div>
  );
}
