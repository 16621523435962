import React, { useCallback, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../styles/app.scss';
import './app.i18n';
import AppRouter from './AppRouter';
import { Footer, Header } from './layout';
import { ScrollToTop } from './ScrollToTop';
import { Cookies, Skip } from './shared';

export const isMobile = window.innerWidth <= 767;

function App() {
  const [renderCookies, setRenderCookies] = useState(true);
  const onUnmountCookies = useCallback(() => {
    setRenderCookies(false);
  }, []);

  return (
    <BrowserRouter>
      {renderCookies ? <Cookies unmount={onUnmountCookies} /> : ''}
      <Skip to="main" />
      <Header />
      <main id="main" tabIndex={-1} className="app">
        <ScrollToTop />
        <AppRouter />
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App