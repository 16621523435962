import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getCurrentLanguage } from './app.i18n';
import { Spinner } from './shared';

export default function AppRouter() {
  const LoadingMessage = () => <Spinner />;
  const language = getCurrentLanguage();

  return (
    <Suspense fallback={<LoadingMessage />}>
      <Switch>
        <Route exact path="/:lang/" component={lazy(() => import('./home/Home'))} />
        <Route exact path="/:lang/quiz" component={lazy(() => import('./quiz/Quiz'))} />
        <Route exact path="/:lang/result" component={lazy(() => import('./result/Result'))} />
        <Redirect
          to={{
            pathname: `/${language}`
          }}
        />
      </Switch>
    </Suspense>
  );
}
