import React from 'react';
import './spinner.module.scss';

export const Spinner = () => {
    return (
        <div styleName="spinner">
            <div>
                <div styleName="spinner-dot1"></div>
                <div styleName="spinner-dot2"></div>
                <div styleName="spinner-dot3"></div>
            </div>
        </div>
    );
};
