export function stopAllIframes(el: any, name: string) {
  const iframes = el.querySelectorAll(`.${name}`);

  if (iframes && iframes.length > 0) {
    iframes.forEach(function (iframe: any) {
      const iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    });
  }
}
