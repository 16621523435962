import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from '~/app/app.i18n';
import './cookies.module.scss';
import { cookies } from './cookies.service';

import { ReactComponent as Close } from '~/assets/icons/close.svg';

type Props = {
  unmount: () => void;
};

export const CONSENT_COOKIE_NAME = 'jsll';
const consentCookieValue = 'on';

export function Cookies({ unmount }: Props) {
  const [t] = useTranslation();
  const language = getCurrentLanguage();

  const isCookieAccepted = useCallback(() => {
    return cookies.get(CONSENT_COOKIE_NAME) === consentCookieValue;
  }, []);

  const setAcceptedCookie = useCallback(() => {
    cookies.set(CONSENT_COOKIE_NAME, consentCookieValue);
    unmount();
  }, [unmount]);

  useEffect(() => {
    if (isCookieAccepted()) {
      unmount();
    }
  }, [isCookieAccepted, unmount]);

  useEffect(() => {
    window.addEventListener('scroll', setAcceptedCookie);
    window.addEventListener('click', setAcceptedCookie);

    return () => {
      window.removeEventListener('scroll', setAcceptedCookie);
      window.removeEventListener('click', setAcceptedCookie);
    };
  }, [setAcceptedCookie]);

  return (
    <>
      {!isCookieAccepted() ? (
        <aside>
          <div id="msccBanner" dir="ltr" styleName="cc-banner active" role="alert" aria-labelledby="msccMessage">
            <div styleName="cc-container">
              <div styleName="cc-description_wrapper">
                <svg styleName="cc-icon cc-v-center" viewBox="0 0 44 44" fill="none" stroke="currentColor">
                  <circle cx="22" cy="22" r="20" strokeWidth="2"></circle>
                  <line x1="22" x2="22" y1="18" y2="33" strokeWidth="3"></line>
                  <line x1="22" x2="22" y1="12" y2="15" strokeWidth="3"></line>
                </svg>
                <span id="msccMessage" styleName="cc-v-center cc-text" tabIndex={0}>
                  {t('cookies.disclaimer')}
                  <a
                    href={`https://privacy.microsoft.com/${language}/privacystatement#maincookiessimilartechnologiesmodule`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={t('cookies.learn-more-aria')}
                    id="msccLearnMore"
                    styleName="cc-link"
                  >
                    {` ${t('cookies.learn-more')}`}
                  </a>
                </span>
              </div>
              <div styleName="cc-close_wrapper">
              <Close styleName="cc-icon cc-v-center cc-float-right cc-icon-close" />
              </div>
            </div>
          </div>
        </aside>
      ) : (
          ''
        )}
    </>
  );
}
