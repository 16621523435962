import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import sensityLogo from '~/assets/images/footer/sensity.svg';
import msLogo from '~/assets/images/footer/microsoft-logo.svg';
import usaTodayLogo from '~/assets/images/footer/usa-today.svg';
import wuLogo from '~/assets/images/footer/wu-logo.svg';
import { Share } from '../../shared';
import './footer.module.scss';
import { FooterLink } from './FooterLink';

type DotProps = {
  hideOnMobile?: boolean;
};
const Dot = ({ hideOnMobile }: DotProps) => <div styleName={`footer-dot ${hideOnMobile ? 'is-hidden' : ''}`}></div>;

export const Footer = () => {
  const [t] = useTranslation();

  return (
    <footer styleName="footer">
      <Container styleName="footer-container">
        <div styleName="footer-logos">
          <div className="d-flex align-items-center">
            <FooterLink url={t('footer.microsoft-link')} imgSrc={msLogo} imgAlt={t('footer.microsoft-alt')} />
            <Dot />
            <FooterLink url={t('footer.wu-link')} imgSrc={wuLogo} imgAlt={t('footer.wu-alt')} />
          </div>
          <Dot hideOnMobile={true} />
          <div className="d-flex align-items-center">
            <FooterLink
              url={t('footer.usa-today-link')}
              imgSrc={usaTodayLogo}
              imgAlt={t('footer.usa-today-alt')}
              isSmall={true}
            />
            <Dot />
            <FooterLink
              url={t('footer.sensity-link')}
              imgSrc={sensityLogo}
              imgAlt={t('footer.sensity-alt')}
              isSmall={true}
            />
          </div>
        </div>
        <div styleName="footer-left">
          <a target="_blank" rel="noopener noreferrer" styleName="footer-about" href="https://aka.ms/aboutspotdeepfakes">
            {t('footer.about')}
          </a>
          <Share />
        </div>
      </Container>
    </footer>
  );
};
