import React from 'react';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './header.module.scss';
import { LngSelect } from './LngSelect';

export function Header() {
  const [t] = useTranslation();

  return (
    <header styleName="header">
      <Container styleName="header-container">
        <Link to="/">
          <h1 styleName="header-link">{t('header.title')}</h1>
        </Link>
        <div>
          <LngSelect />
        </div>
      </Container>
    </header>
  );
}
