import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { getCurrentLanguage, getLanguagesList } from '~/app/app.i18n';

type Data = {
  label: string;
  value: string;
};

export function LngSelect() {
  const [t] = useTranslation();
  const lang = getCurrentLanguage();
  const languagesList = getLanguagesList();
  const [data, setData] = useState<Data[]>([]);

  const initData = () => {
    if (window.innerWidth <= 768) {
      setData(dataMobile);
      return;
    }
    setData(dataDesktop);
  };

  const dataDesktop: Data[] = languagesList.map((language) => {
    return {
      label: t(`global.${language[0]}`),
      value: language[1]
    };
  });

  const getLanguageCode = (lang: string): string => {
    return lang.substring(0, lang.indexOf('-'));
  };

  const dataMobile: Data[] = languagesList.map((language) => {
    return {
      label: t(`global.${getLanguageCode(language[1])}`).toUpperCase(),
      value: language[1]
    };
  });

  const selectedIndex = dataDesktop.findIndex((langData) => {
    return langData.value.toLowerCase() === lang.toLowerCase();
  });

  const onChangeSelect = useCallback((option) => {
    window.location.href = `/${option.value}/`;
  }, []);

  return (
    <>
      {data.length <= 0 ? initData() : null}
      <Select
        classNamePrefix="react-select react-select"
        aria-label={t('home.select-language')}
        isSearchable={false}
        defaultValue={data[selectedIndex]}
        options={data}
        onChange={onChangeSelect}
      />
    </>
  );
}
